<script lang="ts">
    import {afterUpdate, onMount} from 'svelte';

    import {setLocale, t} from '../lib/i18n.js';
    import {addGoogleTag} from '../lib/analytics';
    import {isMobile} from "../lib/resizable.js";
    import {Street} from '../models/street.model';
    import {Data} from '../models/data.model';
    import {MapCoordinates} from '../models/map.model';
    import {
        formatDate,
        getStreetName
    } from '../lib/common';
    import {
        getEponimType,
        isValidEponim
    } from '../lib/street';
    import {
        getPinWithFilledCircleSvg,
        getSelectedPinWithFilledCircleSvg,
        getStreetMarker,
        getStreetPolyline,
        getPointLatLng,
        initMap,
        eventTrigger,
        getMapCenter,
    } from '../lib/map/map';

    export let data: Data[],
        app_locale: string,
        zoom: number,
        apiKey: string,
        street: Street|null,
        mapCoordinates: MapCoordinates;

    let map;
    let markersList = [];
    let streetPath = null;
    let previousStreetsData = null;
    const minZoom: number = 11;
    let selectedMarker;
    let mapInitialized: boolean = false;
    let popupRef: HTMLElement | null = null;
    let mapContainerRef: HTMLElement | null = null;

    setLocale(app_locale); //NOTICE ????

    async function initHomeMap() {
        map = await initMap(apiKey, mapContainerRef, zoom, mapCoordinates, "HOME_MAP_ID", minZoom);
        map.addListener('tilesloaded', function () {
            mapInitialized = true;
            initMapData();
        });
    }

    onMount(async () => {
        // loadGoogleMaps();
    })

    afterUpdate(() => {
        if (!mapInitialized) {
            initHomeMap();
            return
        }

        initMapData();
    });

    function initMapData() {
        if (previousStreetsData != data) {
            initStreetMarkers();
            refreshMapWithStreetsData();
        }

        if (street) {
            let marker = markersList.find(marker => +marker.zIndex  == street.id);
            eventTrigger(marker, 'click');
        }
    }

    function initStreetMarkers() {
        markersList.forEach(marker => {
            marker.removeEventListener('click');
            marker.setMap(null);
        });
        markersList = data.flatMap(streetsData => streetsData["streets"])
            .map(streetData => {
                let mapCoordinates = {
                    lat: streetData['lat'],
                    lng: streetData['lng']
                };
                let streetName = getStreetName(streetData, app_locale);
                let marker = getStreetMarker(map, mapCoordinates, streetName, streetData.id);
                marker.addListener('click', function () {
                    street = streetData;
                    addGoogleTag({
                        event: 'click_on_street_pin',
                        category: 'map',
                        label: `${street.new_name['en-US']} street was selected on the map.`
                    });
                    showStreetInformation(marker);
                });

                return marker;
            });
    }

    function refreshMapWithStreetsData() {
        map.setZoom(+zoom);
        map.setCenter(getPointLatLng(mapCoordinates.lat, mapCoordinates.lng));

        if (popupRef) {
            popupRef.style.display = 'none';
        }

        if (streetPath) {
            streetPath?.setMap(null);
        }

        previousStreetsData = data;
    }

    function deactivationPreviousSelectedMarker() {
        if (selectedMarker) {
            selectedMarker.content = getPinWithFilledCircleSvg();
        }
    }

    function showStreetInformation(marker) {
        updatedSelectedMarker(marker);
        updateMapForSelectedMarker();
        streetPath = getStreetPolyline(street, map, streetPath);
    }

    function updatedSelectedMarker(streetMarker) {
        deactivationPreviousSelectedMarker();
        selectedMarker = streetMarker;
        streetMarker.content = getSelectedPinWithFilledCircleSvg();
    }

    function updateMapForSelectedMarker() {
        map.setZoom(+zoom + 3);
        map.panTo(selectedMarker.position);
        $isMobile
            ? offsetCenter(selectedMarker.position, 0, 100)
            : offsetCenter(selectedMarker.position, 0, 150);
    }

    function offsetCenter(latlng, offsetX, offsetY) {
        const newCenter = getMapCenter(map,latlng, offsetX, offsetY);
        map.panTo(newCenter);
    }

    function handleUnselectStreet() {
        street = null;
        getStreetPolyline(street, map, streetPath);
        deactivationPreviousSelectedMarker();
    }
</script>

<section id="map-container">
    {#if street}
        <div id="street-popup" class="popup">
            <div class="d-flex justify-content-between align-items-center mb-2">
                <div class="map-title">
                    {street.new_name[app_locale]}
                </div>

                <button on:click={handleUnselectStreet} class="cursor-pointer map-btn-close">
                    <img src="/public/assets/images/icons/close-gray.svg" alt="Close">
                </button>
            </div>

            {#if street.object_type[app_locale]}
                <div class="map-title mb-2">({street.object_type[app_locale]})</div>
            {/if}
            <div class="map-text mb-1">{$t("street.old_name")}:</div>
            <div class="map-title mb-2">{street.old_name[app_locale]}</div>
            {#if street.area}
                <div class="map-text mb-1">{$t("street.district")}</div>
                <div class="map-title mb-2">
                    {street.area.new_name[app_locale]} ({street.area.old_name[app_locale]})
                </div>
            {/if}
            <div class="map-text mb-1">{$t("street.rename_date")}:</div>
            <div class="map-title mb-2">{formatDate(street.act.date, $t('months'))}</div>
            {#if getEponimType(street.eponim_type)}
                <div class="map-eponim mb-2">{$t(getEponimType(street.eponim_type))}</div>
            {/if}
            <div class="map-text mb-2">{street.act.name[app_locale]}</div>
            <a class="map-text mb-2"
               target="_blank"
               rel="noopener noreferrer"
               href="{street.act.link}"
               on:click={() => addGoogleTag({
                        event: 'download_street_act',
                        category: 'link',
                        label: 'Street act is downloaded.'
               })}>
                {$t("street.download")}
            </a>
            <a class="map-btn-link d-flex align-items-center justify-content-center mt-3"
               on:click={() => addGoogleTag({
                        event: 'open_street_details',
                        category: 'link',
                        label: 'Street details link is opened.'
               })}
               href="/street/view/{street.slug}">
                {$t("details")}
                <img class="mx-1" src="/public/assets/images/icons/right.svg" alt="Link">
            </a>
            {#if getEponimType(street.eponim_type) && isValidEponim(street.eponim)}
                <a target='_blank'
                   rel="noopener noreferrer"
                   class="map-btn-link d-flex align-items-center justify-content-center mt-2"
                   href="{street.eponim}"
                   on:click={() => addGoogleTag({
                        event:'open_eponim_link_in_new_version',
                        category: 'link',
                        label: 'Eponim link is opened.'
                    })}>
                    {$t(getEponimType(street.eponim_type))}
                    <img class="mx-1" src="/public/assets/images/icons/link.svg" alt="Link">
                </a>
            {/if}
        </div>
    {/if}
    <div bind:this={mapContainerRef} id="map" class="map street-map"></div>
</section>

<style lang="less">
    #map {
        background: #c5dffb;
        background-size: cover;
        position: relative;
        overflow: hidden;
    }

    .map {
        width: calc(100vw - 650px);
        height: calc(100vh - 130px);
        position: relative;
    }

    .street-map {
        border-radius: 30px;

        & iframe + div {
            border: none !important;
        }
    }

    .popup {
        left: calc(446px + 80px + 45px);
        top: calc(210px + (100vh - 300px) / 3);
        position: absolute;
        background-color: white;
        padding: 24px;
        border: 1px solid #ccc;
        border-radius: 5px;
        z-index: 1000;
        width: 320px;
        overflow-x: auto;
        max-height: calc(100vh - (101px + 330px));
    }

    .map-title {
        color: #020202;
        font-family: e-Ukraine sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
    }

    .map-eponim {
        color: #020202;
        font-family: e-Ukraine sans-serif;
        font-size: 14px;
        font-weight: 400;
    }

    .map-text {
        color: #B5B5B5;
        font-family: e-Ukraine sans-serif;
        font-size: 13px;
        font-weight: 300;
    }

    .map-btn-close {
        border: none;
        background: none;
    }

    .map-btn-link {
        height: 48px;
        padding: 10px 16px;
        border-radius: 5555px;
        border: 1px solid #DCDCDC;
        color: #020202;
        font-family: e-Ukraine sans-serif;
        font-size: 14px;
        font-weight: 400;
        text-decoration: none;
    }


    @media (max-width: 992px) {
        .map {
            width: calc(100vw - 160px);
            height: calc(100vh - 300px);
        }

        .popup {
            left: 80px;
            max-height: calc((100vh - 300px) / 2.25);
            top: calc(210px + (100vh - 300px) / 2);
        }
    }

    @media (max-width: 520px) {
        .map {
            width: calc(100vw - 32px);
            border-radius: 0;
        }

        .popup {
            width: calc(100vw - 32px);
            left: 16px;
        }
    }
</style>
