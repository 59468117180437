<script lang="ts">
    import {onMount} from 'svelte';
    import {t} from '../lib/i18n.js';

    import {appConfigStore} from "../stores.js";
    import {addGoogleTag} from '../lib/analytics';
    import {isMobile} from '../lib/resizable';
    import {Data, YearData} from '../models/data.model';
    import {Street} from '../models/street.model';
    import {Ad} from '../models/ad.model';
    import {MapCoordinates} from '../models/map.model';

    import HomeTable from '../Components/HomeTable.svelte';
    import AppLayout from '../Layouts/AppLayout.svelte';
    import Map from '../Components/Map.svelte';

    export let data: Data[],
        // ready: boolean,
        ads: Ad[];

    let appConfig = $appConfigStore;
    let searchTerm: string = '';
    let filteredStreetData: Data[] = [...data];
    let yearData: YearData[] = [];
    let selectYear: number | null = null;
    let selectedStreet: Street | null = null;
    let isMapSelected: boolean = false;
    let mapCoordinates: MapCoordinates = {lat: appConfig.map.default_lat, lng: appConfig.map.default_lng};

    $: isHomeMapVisible = !$isMobile || ($isMobile && isMapSelected);
    $: isHomeTableVisible = !$isMobile || !isHomeMapVisible;

    onMount(() => {
        addGoogleTag({
            event: 'open_home_page_in_new_version',
            category: 'page',
            label: 'Home page is opened.',
        });
        filtration();
        yearData = getStreetYearData(data);
    });

    function filtration(actYear: number | null = null): void {
        let values: Data[] = [...data];

        if (searchTerm) {
            values = values.map((data: Data) => ({
                ...data,
                streets: getStreets(data.streets),
            }));
            yearData = getStreetYearData(values);
        } else {
            yearData = getStreetYearData(data);
        }
        if (actYear) {
            values = values.filter((data: Data) => actYear === (new Date(data.act.date).getFullYear()));
        }

        filteredStreetData = values;
        selectYear = actYear;
    }

    function getStreets(streets: Street[]) {
        return streets.filter((street: Street) =>
            street.old_name[appConfig.app_locale].toLowerCase().includes(searchTerm.toLowerCase()) ||
            street.new_name[appConfig.app_locale].toLowerCase().includes(searchTerm.toLowerCase())
        );
    }

    function getStreetYearData(values: Data[]): YearData[] {
        const result: YearData[] = [];
        values.forEach(data => {
            const date = new Date(data.act.date);
            const year = date.getFullYear();
            if (!result[year]) {
                result[year] = {
                    year: year,
                    count: 0,
                };
            }
            result[year].count += data.streets.length;
        });

        return Object.values(result).sort((a, b) => b.year - a.year);
    }

    function handleButtonClick(actYear: number | null): void {
        filtration(actYear);
        selectedStreet = null;
        addGoogleTag({
            event: 'filter_streets_by_year',
            category: 'select',
            label: 'Streets are chosen by year.',
        });
    }

    function handleClickOnList() {
        isMapSelected = false;
    }

    function handleClickOnMap() {
        isMapSelected = true;
    }
</script>

<svelte:head>
    <title>{$t('home.meta_title', {city:appConfig.city_name, domain: appConfig.domain})}</title>
    <meta property="keywords" content="{$t('home.meta_keywords', {city:appConfig.city_name})}"/>
    <meta property="description" content="{$t('home.meta_description', {city:appConfig.city_name})}"/>
    <meta property="og:keywords" content="{$t('home.meta_keywords', {city: appConfig.city_name})}"/>
    <meta property="og:description" content="{$t('home.meta_description', {city:appConfig.city_name})}"/>
</svelte:head>

<AppLayout>
    <section class="home">
        <div class="d-sm-block d-md-block d-lg-none">
            <div class="home-switch-container d-flex justify-content-center">
                <div class="home-switch d-flex align-items-center justify-content-around">
                    <button class="d-flex align-items-center home-switch-btn" on:click={handleClickOnList}>
                        <div class="home-switch-icon-list mx-1 {isHomeTableVisible ? 'active' : ''}"></div>
                        <div class="home-switch-text {isHomeTableVisible ? 'active' : ''}">{$t("home.list")}</div>
                    </button>
                    <button class="d-flex align-items-center home-switch-btn" on:click={handleClickOnMap}>
                        <div class="home-switch-icon-map mx-1 {isHomeMapVisible ? 'active' : ''}"></div>
                        <div class="home-switch-text {isHomeMapVisible ? 'active' : ''}">{$t("home.map")}</div>
                    </button>
                </div>
            </div>
        </div>
        <div class="grid">
            <div class="search-wrapper">
                <input class="search-input" type="text" id="street_search" name="street_search"
                       on:keyup={() => filtration(selectYear)}
                       on:click={() => addGoogleTag({
                            event: 'filter_streets_by_name',
                            category: 'select',
                            label: searchTerm,
                          })
                       }
                       bind:value={searchTerm}
                       placeholder={$t("street_names")}>
                <div class="d-flex align-items-center justify-content-start flex-nowrap my-3 streets-years">
                    <button on:click={() => handleButtonClick(null)} class:active={selectYear === null} class="act_button">
                        {$t("all")}
                    </button>
                    {#each yearData as data}
                        {#if data.count !== 0}
                            <button on:click={() => handleButtonClick(data.year)}
                                    class:active={data.year === selectYear}
                                    class="act_button">
                                {data.year} ({data.count})
                            </button>
                        {/if}
                    {/each}
                </div>
                <div class="act street-table {isHomeTableVisible ? 'd-block' : 'd-none'}">
                    <HomeTable
                        bind:data="{filteredStreetData}"
                        app_locale="{appConfig.app_locale}"
                        dataLength="{data.length}"
                        bind:selectedStreet="{selectedStreet}"
                        ads="{ads}"
                        isNewList="{!!selectYear}"
                        bind:isMapSelected="{isMapSelected}"
                    ></HomeTable>
                </div>
            </div>
            <div class="mt-3 {isHomeMapVisible ? 'd-block' : 'd-none'}">
                <Map
                    apiKey="{appConfig.map.google_map_key}"
                    app_locale="{appConfig.app_locale}"
                    zoom="{appConfig.map.zoom}"
                    bind:data="{filteredStreetData}"
                    bind:street="{selectedStreet}"
                    bind:mapCoordinates="{mapCoordinates}"
                ></Map>
            </div>
        </div>
        <div class="d-block d-sm-none">
            <a href="https://bit.ly/rnkr-onixteam" target="_blank" rel="noopener noreferrer"
               class="fixed-button d-flex justify-content-center align-items-center">
                {$t("join_to")}
                <img class="mx-2 onix-gray-logo" src="/public/assets/images/icons/onix-gray-logo.svg" alt="ONIX">
            </a>
        </div>
    </section>
</AppLayout>

<style>
    .home-switch-btn {
        background: none;
        border: none;
    }

    .home-switch {
        border-radius: 100px;
        border: 1px solid #F1F1F1;
        background: #FFF;
        box-shadow: 0 4px 4px 0 rgba(3, 3, 3, 0.11);
        width: 240px;
        height: 48px;
        padding: 0 20px;
    }

    .home-switch-container {
        position: fixed;
        bottom: 65px;
        left: 0;
        right: 0;
        z-index: 999;
    }

    .home-switch-icon-list {
        width: 20px;
        height: 20px;
        background-color: #818181;
        -webkit-mask: url("/public/assets/images/icons/list.svg") no-repeat center;
        mask: url("/public/assets/images/icons/list.svg") no-repeat center;
    }

    .home-switch-icon-list.active {
        background-color: #0061F3;
    }

    .home-switch-icon-map {
        width: 20px;
        height: 20px;
        background-color: #818181;
        -webkit-mask: url("/public/assets/images/icons/map.svg") no-repeat center;
        mask: url("/public/assets/images/icons/map.svg") no-repeat center;
    }

    .home-switch-icon-map.active {
        background-color: #0061F3;
    }

    .home-switch-text {
        color: #818181;
        font-family: e-Ukraine sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 20px;
    }

    .home-switch-text.active {
        color: #0061F3;
    }

    .search-wrapper {
        grid-area: search-wrapper;
    }

    .onix-gray-logo {
        width: 60px;
        height: 17px;
    }

    .grid {
        display: grid;
        grid-template-areas: 'search-wrapper streets-map' 'street-table streets-map';
        column-gap: 40px;
    }

    .fixed-button {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100vw;
        background-color: #007bff;
        color: #fff;
        padding: 10px 20px;
        height: 56px;
        text-decoration: none;
        cursor: pointer;
    }

    .fixed-button:hover {
        background-color: #0056b3;
    }

    .act_button {
        padding: 10px 16px;
        border-radius: 5555px;
        border: 1px solid #F1F1F1;
        background: #FFF;
        width: auto;
        margin-right: 8px;
        white-space: nowrap;
        color: #818181 !important;
        font-family: e-Ukraine sans-serif;
        font-size: 12px !important;
        font-weight: 400;
    }

    .act {
        color: #030303;
        font-family: e-Ukraine sans-serif;
    }

    .act_button.active {
        border: 1px solid #0061F3;
        color: #0061F3 !important;
        background: #0061F319;
    }

    .search-input {
        border-radius: 12px;
        border: 2px solid #F1F1F1;
        width: 446px;
        height: 56px;
        padding: 24px 24px 24px 60px;
        background-color: #F8F8F8;
        background: url("/public/assets/images/icons/search.svg") no-repeat left;
        background-position: 24px center;
    }

    /*todo*/
    .search-input::placeholder {
        color: #818181;
        font-family: e-Ukraine sans-serif;
        font-display: swap;
        src: url(../fonts/e-Ukraine/e-Ukraine-Regular.otf) format("opentype");
        font-size: 18px !important;
        font-weight: 400 !important;

    }

    .street-table {
        max-height: calc(100vh - 260px);
        width: 446px;
        overflow-x: auto;
        grid-area: street-table;
    }

    .streets-years {
        max-width: 446px;
        overflow-x: auto;
    }

    @media (max-width: 992px) {
        .grid {
            grid-template-areas: 'search-wrapper' 'streets-map' 'street-table';
            column-gap: 0;
        }

        .streets-years {
            max-width: calc(100vw - 160px);
        }

        .search-input {
            width: calc(100vw - 80px * 2);
        }

        .street-table {
            width: auto;
        }

        /*.streets-map {*/
        /*    width: calc(100vw - 160px);*/
        /*    height: calc(100vh - 300px);*/
        /*}*/
    }

    @media (max-width: 520px) {
        .streets-years {
            width: calc(100vw - 32px);
        }

        .search-input {
            width: calc(100vw - 32px);
        }

        .street-table {
            width: calc(100vw - 32px);
        }

        /*.streets-map {*/
        /*    width: calc(100vw - 32px);*/
        /*}*/

        .streets-years {
            max-width: calc(100vw - 32px);
        }
    }
</style>
