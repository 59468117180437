<script lang="ts">
    import Carousel from 'svelte-carousel';

    import {Ad} from '../models/ad.model';
    import {addGoogleTag} from '../lib/analytics';

    export let ads: Ad[];

    let carousel;

    function handlePrev() {
        carousel.goToPrev();
    }
    function handleNext() {
        carousel.goToNext();
    }

    function pauseCarousel() {
        carousel.pause();
    }

    function resumeCarousel() {
        carousel.resume();
    }

</script>

<section class="ads-container d-sm-block">
    <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
        <Carousel
            bind:this={carousel}
            dots={false}
            autoplay={true}
            on:mouseenter={pauseCarousel}
            on:mouseleave={resumeCarousel}
        >
            <div class="carousel-inner">
                {#each Object.values(ads) as ad, index}
                    <div class="carousel-item {index === 0 ? 'active' : ''}">
                        <a href="{ad.link}"
                           target='_blank'
                           rel="noopener noreferrer"
                           on:click={() => addGoogleTag({
                            event: 'followed_ad_link',
                            category: 'click',
                            label: 'Followed the ad link.'
                           })}
                        >
                            <img src="{ad.image.url}" class="d-block w-100" alt="{ad.description}">
                        </a>
                    </div>
                {/each}
            </div>
            <div slot="prev">
                <button class="carousel-control-prev"  on:click="{handlePrev}" type="button" data-bs-target="#carouselExampleControls"
                        data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
            </div>
            <div slot="next">
                <button class="carousel-control-next" on:click="{handleNext}" type="button" data-bs-target="#carouselExampleControls"
                        data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>
        </Carousel>
    </div>
</section>

<style>
    .ads-container {
        width: 100%;
        margin-bottom: 18px;
        height: 232px;
    }

    @media (max-width: 468px) {
        .ads-container {
            width: calc(100vw - 32px);
            height: auto;
        }
    }
</style>
