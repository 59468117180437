<script lang="ts">
    import {afterUpdate, onMount} from 'svelte';

    import {t} from '../lib/i18n.js';
    import {addGoogleTag} from '../lib/analytics';
    import {formatDate} from '../lib/common';

    import {Data} from '../models/data.model';
    import {Street} from '../models/street.model';
    import {Ad} from '../models/ad.model';
    import Ads from '../Components/Ads.svelte';

    export let data: Data[],
        app_locale: string,
        dataLength: number,
        selectedStreet: Street,
        ads: Ad[],
        isNewList: boolean = false,
        isMapSelected: boolean;


    let visibleData: Data[] = [];
    const rowHeight: number = 60;
    let loadedRowCount: number = 0;
    let filteredStreets = [];
    const tableHeight = window.innerHeight - 260;
    let previousData: Data[] = [];

    function handleStreetChange(street: Street): void {
        selectedStreet = street;
        isMapSelected = true;

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        addGoogleTag({
            event: 'select_street_in_table_in_new_version',
            category: 'table',
            label: `${selectedStreet.new_name['en-US']} street was selected in the table.`
        });
    }

    function handleScroll(event) {
        const container = event.currentTarget;
        const scrollPosition = container.scrollTop;

        const totalCount = visibleData.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.streets.length;
        }, 0);

        const threshold = totalCount * rowHeight - tableHeight;

        if (scrollPosition > threshold) {

            loadedRowCount++;

            if (filteredStreets.length >= loadedRowCount && filteredStreets.length !== visibleData.length) {
                visibleData = filteredStreets.slice(0, loadedRowCount);
            }
        }
    }

    function calculateLoadedRowCount() {
        let totalHeight = 0;

        loadedRowCount = isNewList ? 0 : loadedRowCount;

        if (previousData != data) {
            for (const data of filteredStreets) {
                totalHeight += rowHeight * data.streets.length;

                loadedRowCount++;

                if (totalHeight >= tableHeight) {
                    break;
                }
            }
        }
    }

    function filtration() {
        filteredStreets = data.filter(item => item.streets.length !== 0);
        calculateLoadedRowCount();
        visibleData = filteredStreets.slice(0, loadedRowCount);
    }

    onMount(() => {
        if (data.length != 0) {
            filtration();
            isNewList = false;
        }
    });

    afterUpdate(() => {
        filtration();
        isNewList = false;
        previousData = data;
    });
</script>

<section id="table-container"
       style="height: calc(100vh - 260px);
       overflow: auto;"
       on:scroll="{handleScroll}"
>
    <div class="d-flex justify-content-center d-none d-lg-block">
        <Ads bind:ads="{ads}"></Ads>
    </div>
    <table class="home-table">
        {#each visibleData as streetData, index}
            {#if streetData.streets.length !== 0}
                {#if index === 0 && data.length === dataLength}
                    <tr>
                        <td colspan="3">
                            <div class="act-title mt-3">{$t("recent")} ({streetData.streets.length})</div>
                        </td>
                    </tr>
                {/if}
                <tr class="act-border">
                    <td colspan="3">
                        <div class="d-flex justify-content-between align-items-center">
                            {#if index === 0 && data.length === data.length}
                                <div class="py-2 act-title">{formatDate(streetData.act.date, $t('months'))}</div>
                            {:else}
                                <div class="act-title py-3">{$t("for")} {formatDate(streetData.act.date, $t('months'))}</div>
                            {/if}
                            <a target="_blank"
                               rel="noopener noreferrer"
                               href="{streetData.act.link}"
                               on:click={() => addGoogleTag({
                                      event: 'open_act_link_in_new_version',
                                      category: 'link',
                                      label: 'Act link is opened.'
                                 })}
                               class="d-flex act-link d-flex justify-content-end home-table-decision">
                                <img class="mx-2" src="/public/assets/images/icons/pdf-file.svg" alt="PDF">
                                <div>{$t("decision")}</div>
                            </a>
                        </div>
                    </td>
                </tr>
                {#each streetData.streets as street}
                    <tr class="act-border my-3" on:click={() => handleStreetChange(street)}>
                        <td class="py-3 street-info old_name">{street.old_name[app_locale]}</td>
                        <td class="py-3 street-info" style="width: 40px">
                            <img src="/public/assets/images/icons/arrow-right.svg" alt="Right">
                        </td>
                        <td class="py-3 street-info new_name">{street.new_name[app_locale]}</td>
                    </tr>
                {/each}
            {/if}
        {/each}
    </table>
</section>

<style>
    .home-table {
        width: 100%;
    }

    .act-border {
        border-bottom: 1px solid #dedede;
        cursor: pointer;
    }

    .act-link {
        text-decoration: none;
        color: #030303;
        font-size: 13px !important;
        font-weight: 400 !important;
    }

    .old_name {
        width: 45% !important;
        font-size: 15px !important;
        font-weight: 300;
        line-height: 20px !important;
        color: #818181;
    }

    .street-info {
        vertical-align: middle;
        text-align: left;
    }

    .act-title {
        font-size: 18px !important;
        font-weight: 500 !important;
    }

    .old_name {
        font-size: 12px !important;
    }

    .new_name {
        font-size: 16px !important;
        padding-left: 1rem !important
    }

    .act-title {
        font-size: 14px;
    }

    @media (max-width: 520px) {
        .old_name {
            width: auto !important;
        }

        .home-table-decision {
            margin-right: calc(100vw - 300px);
        }
    }
</style>
